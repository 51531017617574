import React from 'react';
import Img from 'gatsby-image';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import * as Cardstyles from './Card.module.scss';
import { FluidObject } from '../types';

interface CardProps {
  slug: string,
  title: string,
  image?: FluidObject,
  src?: string
}

const Card: React.FC<CardProps> = ({ slug, title, image, src }: CardProps) => {
  const appInsights = useAppInsightsContext();

  function trackingEvent () {
    appInsights.trackEvent({ name: title.replaceAll(/\s/g, '_') });
  }

  return (
    <div className={Cardstyles.card}>
      <a href={slug} target='_blank' onClick={trackingEvent} onAuxClick={trackingEvent}>
        {image ? (
          <Img
            className={Cardstyles.image}
            imgStyle={{ objectFit: 'contain' }}
            fluid={image}
          />
        ) : (
          <img
            className={Cardstyles.svg}
            src={src}
          />
        )}
        <p>{title}</p>
      </a>
    </div>
  );
};

export default Card;
