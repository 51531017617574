import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import './index.scss';
import icon from '../images/icon.png';
import CardList from '../components/CardList';
import StatusList from '../components/StatusList';
import { MetaDataQuery } from '../types';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=8905791e-1917-4a3f-8a73-0a030848a5d5;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/',
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const IndexPage: React.FC = () => {
  const metaData: MetaDataQuery = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Helmet title={metaData.site.siteMetadata.title}>
        <link rel='icon' href={icon} />
      </Helmet>
      <div className='container'>
        <div className='container-wrapper'>
          <div className='card-list'>
            <h1>Welcome to Prima Power Services</h1>
            <CardList />
          </div>
          <div className='status-list'>
            <StatusList />
          </div>
        </div>
      </div>
    </AppInsightsContext.Provider>
  );
};

export default IndexPage;
