import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Card from './Card';
import * as CardListstyles from './CardList.module.scss';
import { ServiceEdge, ServicesQuery } from '../types';

const CardList: React.FC = () => {
  const servicesData: ServicesQuery = useStaticQuery(graphql`
    query {
    allServicesJson {
      edges {
        node {
          title
          slug
          featuredImg {
            childImageSharp {
              fluid(quality: 100) {
                 ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
  `);

  return (
    <div className={CardListstyles.cards}>
      {servicesData.allServicesJson.edges.map((edge: ServiceEdge) => {
        const { title, slug, featuredImg } = edge.node;
        const isImage = featuredImg.childImageSharp === null && featuredImg.extension === 'svg';
        return (
          <Card
            key={title}
            title={title}
            slug={slug}
            src={isImage ? featuredImg.publicURL : undefined}
            image={!isImage ? featuredImg.childImageSharp.fluid : undefined}
          />
        );
      })}
    </div>
  );
};

export default CardList;
